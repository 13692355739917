<template>
  <div class="Index">
    <NavList
      :btn-connect="btnConnect"
      @handleCallBack="upBtnConnect"
    />
    <Atlantis @handleCallBack="upBtnConnect" />
  </div>
</template>

<script>
// @ is an alias to /src
// import NavList from '../components/Nav.vue'
// import Atlantis from '../components/Atlantis.vue'
export default {
  name: 'IndexView',
  components: {
    NavList: () => import('../components/Nav.vue'),
    Atlantis: () => import('../components/Atlantis.vue')
  },
  props: {
    btnConnect: {
      type: String,
      default: 'Connect'
    }
  },
  methods: {
    async upBtnConnect() {
      const provider = window.ethereum
      if (typeof provider !== 'undefined') {
        const myaccount = await provider.request({
          method: 'eth_requestAccounts'
        })
        this.$emit('handleCallBack', myaccount[0].slice(0, 6) + '...')
      } else {
        this.$toast.warning('MetaMask is not installed!')
      }
    }
  }
}
</script>
